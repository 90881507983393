<template>
  <v-card tile flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense justify="space-between">
          <span class="title">Manage Agreements</span>
          <v-btn color="color3" small text icon @click.stop="loadStatuses" :disabled="loading">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
      </v-row>
    </v-container>
    <v-card-text class="pt-0">
      <v-divider class="mb-2"></v-divider>
      <v-window v-model="window" touchless>
        <v-window-item :value="0">
          <v-text-field
            color="color3"
            append-icon="fas fa-search"
            label="Search"
            hide-details
            v-model="search"
            solo-inverted
            clearable
          ></v-text-field>
          <v-data-table
            :headers="[
              {text: 'Firstname', value: 'firstName'},
              {text: 'Lastname', value: 'lastName'},
              {text: 'Status', value: 'status'},
              {text: 'Send Count', value: 'sendCount'},
              {text: 'Last Sent', value: 'lastSent'},
              {text: '', value: 'view'},
            ]"
            :items="data"
            multi-sort
            :footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
            item-key="playerProfileId"
            v-model="selected"
            show-select
            :search="search"
          >
            <template v-slot:[`item.lastSent`]="{item}">
              {{ item.lastSent | localDt }}
            </template>
            <template v-slot:[`item.status`]="{item}">
              <v-chip :color="item.status === 'Signed' ? 'success' : item.status === 'Unsigned' ? 'error' : 'warning'">
                <v-icon class="mr-2">
                  {{item.status === 'Signed' ? 'far fa-check-circle' : item.status === 'Unsigned' ? 'far fa-times-circle' : 'far fa-exclamation-circle'}}
                </v-icon>
                {{item.status}}
              </v-chip>
            </template>
            <template v-slot:[`item.view`]="{item}">
              <player-agreement-dialog
                v-if="item.status !== 'Unsent'"
                :playerProfileId="item.playerProfileId"
                :agreementId="agreement.id"
                :agreement="agreement.name"
                :noTournamentAllowed="true"
                :playerName="`${item.firstName} ${item.lastName}`"
                color="color3"
              ></player-agreement-dialog>
            </template>
          </v-data-table>
          <v-fab-transition>
            <v-btn
              color="color3 color3Text--text"
              v-if="selected && selected.length"
              :fixed="!isIntersecting"
              :bottom="!isIntersecting"
              :center="!isIntersecting"
              @click.stop="resendClick"
              :disabled="loading"
            >
              Resend ({{selected.length}})
            </v-btn>
          </v-fab-transition>

        </v-window-item>
        <v-window-item :value="1">
          <v-card flat>
            <v-card-title>
              Resending {{resendName}}
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12" md="6" xl="4" v-for="dto in dtos" :key="dto.key">
                    <v-card>
                      <v-card-title class="pb-0">
                        {{dto.name}}
                      </v-card-title>
                      <v-card-text class="py-0">
                        <v-checkbox
                          label="Send to email addresses on file"
                          v-model="dto.emailsOnFile"
                          color="success"
                          :disabled="loading"
                        ></v-checkbox>
                          <v-row dense v-for="(alt, i) in dto.altEmailList" :key="`${dto.key}-${i}`">
                            <v-col cols="4" class="py-0">
                              <v-text-field
                                class="mt-0"
                                label="Name"
                                v-model="alt.name"
                                hide-details
                                :disabled="loading"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                              <v-text-field
                                class="mt-0"
                                label="Email"
                                v-model="alt.email"
                                hide-details
                                :disabled="loading"
                              ></v-text-field>
                            </v-col>
                            <v-col class="py-0 d-flex align-end">
                              <v-btn x-small color="error" text icon @click.stop="() => dto.altEmailList.splice(i)" :disabled="loading">
                                <v-icon>fas fa-trash</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="color3Text color3--text"
                          small fab
                          @click.stop="() => dto.altEmailList.push({name: null, email: null})"
                          :disabled="loading"
                        >
                          <v-icon>fas fa-plus</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-fab-transition>
                      <v-btn color="color3" text @click="showAddNote = true" v-if="!showAddNote">
                        Include a message
                      </v-btn>
                    </v-fab-transition>
                  </v-col>
                  <v-expand-transition>
                    <v-textarea
                      label="Message to send with the waiver"
                      v-if="showAddNote"
                      v-model="addNote"
                    ></v-textarea>
                  </v-expand-transition>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                fab
                :fixed="!isIntersecting"
                :bottom="!isIntersecting"
                :left="!isIntersecting"
                @click.stop="window=0"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
              <v-btn
                color="success"
                fab
                :fixed="!isIntersecting"
                :bottom="!isIntersecting"
                :left="!isIntersecting"
                :style="!isIntersecting ? 'margin-left: 60px' : null"
                @click.stop="sendThem"
                :loading="loading"
              >
                <v-icon>fas fa-paper-plane</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
    <div v-intersect="onIntersect"></div>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex'
const PlayerAgreementDialog = () => import('@/components/Agreements/PlayerAgreementDialog')

export default {
  props: ['active', 'agreement'],
  data () {
    return {
      loading: false,
      data: [],
      search: null,
      window: 0,
      selected: [],
      dtos: null,
      resendName: null,
      showAddNote: false,
      addNote: null,
      isIntersecting: false
    }
  },
  computed: {
    // ...mapGetters(['tournament']),
    dialog: {
      get () { return this.pAgree.length > 0 },
      set (val) {
        if (!val) this.pAgree = []
      }
    },
    bottomNav: {
      get () {
        return this.selected.length > 0
      },
      set (val) {
        if (!val) {
          this.selected = []
        }
      }
    }
  },
  methods: {
    loadStatuses () {
      if (!this.active) return
      this.loading = true
      this.$VBL.agreements.organization.getPlayers(this.agreement.id)
        .then(r => {
          this.data = r.data
          this.data.forEach(f => {
            f.status = f.dtSigned ? 'Signed' : 'Unsigned'
          })
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    resendClick () {
      const s = this.selected
      this.resendName = this.agreement.name
      this.dtos = s.map(p => {
        return {
          playerProfileId: p.playerProfileId,
          // tournamentId: this.tournament.id,
          agreementId: this.agreement.id,
          name: `${p.firstName} ${p.lastName}`,
          emailsOnFile: true,
          altEmailList: [],
          key: p.key
        }
      })
      this.window = 1
      this.selected = []
      setTimeout(() => {
        this.$vuetify.goTo(0)
      }, 333)
    },
    sendThem () {
      if (!this.dtos) return
      this.loading = true
      this.$VBL.agreements.tournament.send({ toBeSent: this.dtos, addNote: this.addNote })
        .then(r => {
          this.loadStatuses()
          this.window = 0
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    onIntersect (entries, observer) {
      this.isIntersecting = entries[0].isIntersecting
    }
  },
  watch: {
    active: 'loadStatuses',
    window: function (val) {
      if (!val) {
        this.addNote = null
        this.showAddNote = false
      }
    }
  },
  components: {
    PlayerAgreementDialog
  },
  mounted () {
    this.loadStatuses()
  }
}
</script>
